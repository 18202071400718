import "./App.css";
import AboutMe from "./components/aboutme";
import Projects from "./components/projects";
import Academics from "./components/academics";

function App() {
  return (
    <div className="flex flex-col items-center">
      <h1 className="text-darknavy font-extrabold text-[42px] kumbh-sans">Jashan's Portfolio</h1>
      <AboutMe className="text-darknavy self-start"/>
      <Academics className="self-start"/>
      <Projects className="self-start"/>
    </div>
  );
}

export default App;
