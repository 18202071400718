export default function Semester(props) {
  return (
    <div className={props.className}>
      <h1 className="text-[20px] font-extrabold">Semester {props.semester.id}</h1>
      <ul className="w-fit">
        {props.semester.subjects.map((subject) => {
          return (
            <li key={subject.name} className="font-bold">
              <span className="mr-[50px]">{subject.name}:</span>
              <span className={`float-right w-[20px] ${subject.grade === "A+" ? "text-[#32CD32]" : "text-[#228B22]"}`}>{subject.grade}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
