import Semester from "./semester";

const semesters = [
  {
    id: 1,
    subjects: [
      { name: "Applied Problem Solving", grade: "A+" },
      { name: "Communicating Across Contexts", grade: "A" },
      { name: "Comp. Principle for Programmer", grade: "A+" },
      { name: "Intro to Programming Using C", grade: "A+" },
      { name: "Intro UNIX/Linux and Internet", grade: "A+" },
    ],
  },
  {
    id: 2,
    subjects: [
      { name: "Introduction to Database Systems", grade: "A+" },
      { name: "Italian Language Skills 1", grade: "A+" },
      { name: "Object Oriented Programming", grade: "A+" },
      { name: "Software Testing", grade: "A+" },
      { name: "Web Programming Principles", grade: "A+" },
    ],
  },
  {
    id: 3,
    subjects: [
      { name: "Advanced Database Services", grade: "A+" },
      { name: "The Body: Bits and Bites", grade: "A+" },
      { name: "Software Development using C++", grade: "A+" },
      { name: "Software Design and Analysis I", grade: "A+" },
      { name: "Web Prog. Tools and Frameworks", grade: "A+" },
    ],
  },
];

export default function Academics(props) {
  return (
    <div className={props.className}>
      <h1 className="text-darknavy font-extrabold text-[32px]">Academics</h1>
      <p className="text-lightnavy font-bold">
        I have achieved President's honour list in all 3 of my semesters by
        maintaining a 4.0 GPA in each.
      </p>
      <div className="flex flex-row">
        {semesters.map((semester) => (
          <Semester semester={semester} className="m-[40px]"/>
        ))}
      </div>
    </div>
  );
}
