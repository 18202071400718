import Project from "./project";

const projects = [
  {
    name: "Housify",
    description:
      "A blockchain based property document management system created with Next.js.",
  },
  { name: "BookDiary", description: "A book review website that uses the google books" +
   "API to support book search for more than 40 million books. Can also be used" },
  { name: "Portfolio", description: "This website! I made it using Express.js and React.js and deployed it on Heroku... on my own domain!" },
];

export default function Projects(props) {
  return (
    <div className={props.className}>
      <h2 className="text-darknavy font-extrabold text-[32px]">Personal Projects</h2>
      <div className="flex flex-row justify-center w-full">
        {projects.map(project => <Project project={project} className="m-[50px]" key={project.name}/>)}
      </div>
    </div>
  );
}
