export default function AboutMe(props) {
  return (
    <div className={props.className}>
      <h2 className="text-darknavy font-extrabold text-[32px]">About me</h2>
      <p className="text-lightnavy font-bold">
        I am studying computer programming at Seneca Polytechnic. I have
        developed web applications using express.js, react.js, and next.js.
      </p>
    </div>
  );
}
